<template>
    <div>
        <com-home ></com-home>
        <com-loading></com-loading>
    </div>
</template>

<script>
import comHome from './comHome.vue'
import comLoading from '@/components/comLoading.vue'

export default {
    name: 'App',
    components: {
        comHome,
        comLoading,
    },
    data() {
        return {
        }
    },
    methods : {
    },
    
    created() {
        this.$c.$root = this;
    },
    beforeMount() {
    },
}
</script>

